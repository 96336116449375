import * as React from 'react';
import { withLayout } from '../../components/layout.tsx';
import { Header, Container } from 'semantic-ui-react';
import { H2 } from '../../components/mdx_blocks/header';

const SalvusFeature = (props) => {
  return <div>bla</div>;
};

class Services extends React.Component<{ data: any }, {}> {
  public render() {
    return (
      <>
        <div
          css={{
            '@media only screen and (max-width: 500px)': {
              marginLeft: '2ex',
              marginRight: '2ex',
            },
          }}
        >
          <Container text textAlign="center">
            <Header
              as="h1"
              css={{
                fontSize: '250% !important',
                marginTop: '0px !important',
                marginBottom: '1em !important',
              }}
            >
              <Header.Content>Services</Header.Content>
            </Header>
          </Container>
          <Container text style={{ paddingTop: '1em' }}>
            In addition to licenses for our core Salvus product we offer a
            number of services around it.
          </Container>
          <Container text style={{ paddingTop: '1em' }}>
            <H2>Technical and Application Support</H2>

            <p>
              Every Salvus license comes basic technical support to ensure
              Salvus runs as designed on all your machines. Further services
              ranging from fast high-priority technical support all the way to
              full application support are available on request.
            </p>
            <p>
              Benefit from expert consulting and specialized assistance in the
              wider field of waveform modeling and inversion. This includes, but
              is not limited to:
            </p>
            <div>
              <ul>
                <li>modeling</li>
                <li>meshing</li>
                <li>scripting guidance</li>
                <li>workflow design</li>
                <li>quality control and</li>
                <li>process management</li>
              </ul>
            </div>
          </Container>
          <Container text style={{ paddingTop: '1em' }}>
            <H2>Custom Development</H2>
            Implementation of custom features and add-ons on request, for
            example tailored pre-and post-processing modules, data converters,
            specialized modeling and workflows, etc.
          </Container>
        </div>
      </>
    );
  }
}

export default withLayout(Services);
